<template>
  <v-dialog v-model="dialog" width="420px" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center flex-column">
        Atualizar Modelo
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation class="pa-4">
        <v-textarea
          outlined
          label="Detalhe o movito da atualização"
          v-model="formData.motivo"
          :clearable="true"
          rows="3"
          required
          :rules="rules.genericRules"
          :readonly="loading"
        ></v-textarea>
      </v-form>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="red" outlined @click="$emit('close')">Cancelar</v-btn>
        <v-btn
          color="gray"
          outlined
          @click="update"
          :loading="loading"
          :disabled="loading"
          >Atualizar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "ModelosAtulizarModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: true,
    rules: rules,
    formData: {
      motivo: "",
    },
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    update() {
      if (this.formValid) this.$emit("update", this.formData.motivo);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal === true) {
        this.formData.motivo = "";
      }
    },
  },
};
</script>
