<template>
  <v-card elevation="1" class="logs">
    <div class="pa-4 grey lighten-3 font-weight-bold">
      Histórico de Atualizações
    </div>
    <hr />
    <div v-if="logs.length > 0" class="body pa-4">
      <div v-for="(log, idx) of logs" :key="idx">
        <div>
          <div class="grey lighten-3 pa-2">
            <p class="caption mb-0">
              {{ log.dataCriacao | dataTimePtBr }}
            </p>
          </div>
          <p class="pa-2 grey lighten-4" v-html="log.mensagem"></p>
        </div>
      </div>
    </div>
    <div v-else class="body pa-4 text-center">
        Nenhuma atualização realizada
    </div>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  name: "ModelosLogsEditar",
  props: {
    logs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  filters: {
    dataTimePtBr: function (value) {
      if (value) {
        return moment(value).format("DD/MM/Y HH[h]:mm[m]");
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.logs .body {
  height: 100%;
  max-height: 507px;
  overflow-y: scroll;
}
.logs .body::-webkit-scrollbar {
  width: 8px;
}
.logs .body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.logs .body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}
.logs .body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.log div {
  border-radius: 6px 6px 0px 0px;
}
.log p {
  border-radius: 0px 0px 6px 6px;
}
</style>
